.inLineBusModalWrap {
    :global {
        .ant-modal-content {
            scrollbar-width: auto;
        }

        ::-webkit-scrollbar {
            width: 5px;
        }

        ::-webkit-scrollbar-thumb {
            background-color: var(--scrollbar-thumb-color);
            border-radius: 5px;
        }

        ::-webkit-scrollbar-track {
            background-color: #384b5d;
        }
    }
}

.RichTextWrap {
    :global {
        .price-wrap {
            color: #fff;
            .no-gutter {
                display: flex;
            }
            .res-bar-odd {
                border-bottom: 1px solid #fff;
                background-color: #384b5d;
            }
            .res-price-info {
                padding: 8px;
                position: relative;
            }
            .border-right {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                width: 1px;
                background-color: #fff;
            }
        }
    }
}
