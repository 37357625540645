.leaveContentWrap,
.attentionContentWrap {
    max-height: 200px;
    :global {
        ::-webkit-scrollbar {
            width: 5px;
        }

        ::-webkit-scrollbar-thumb {
            background-color: var(--scrollbar-thumb-color);
            border-radius: 5px;
        }

        ::-webkit-scrollbar-track {
            background-color: #384b5d;
        }
    }
}

.leaveContentWrap > div,
.attentionContentWrap > div {
    max-height: 200px;
}

.modalWrap {
    :global {
        .ant-modal-content {
            max-height: calc(100vh - var(--content-max-height));
            overflow: auto;
            scrollbar-width: none;
        }

        .ant-modal-body {
            width: 100%;
        }

        .ant-modal-footer {
            display: none;
        }
    }
}

.inLienBusTable {
    :global {
        .ant-table-content {
            border-radius: 8px;
        }
        .ant-spin-container {
            .ant-table {
                background: transparent;
            }
        }
        .ant-table-content .ant-table-thead > tr > th {
            text-align: center;
        }
        .ant-table-tbody tr:last-child td:first-child {
            border-bottom-left-radius: 8px;
        }
        .ant-table-tbody tr:last-child td:last-child {
            border-bottom-right-radius: 8px;
        }
    }
}
