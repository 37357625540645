.imageGroupWrap {
    :global {
        .ant-image-preview-switch-left,
        .ant-image-preview-switch-right {
            background: transparent;
            color: #ffffff;
        }

        .ant-image-preview-switch-left:hover,
        .ant-image-preview-switch-right:hover {
            background: transparent;
        }

        // .ant-image-preview-body {
        //     .ant-image-preview-img {
        //         width: 100%;
        //         height: 100%;
        //         max-height: 100%;
        //     }
        // }

        .ant-image-preview-switch-left {
            left: var(--prev-arrow-left);
        }
        .ant-image-preview-switch-right {
            left: var(--next-arrow-left);
        }

        .ant-image-preview-switch-left-disabled,
        .ant-image-preview-switch-right-disabled {
            opacity: 0.5;
        }

        .ant-image-preview-footer {
            .ant-image-preview-progress {
                display: none;
            }
        }
    }
}

.imagePreviewWrap {
    max-height: 85vh;
    max-width: 85vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    :global {
        img.ant-image-preview-img {
            max-height: 85vh;
            max-width: 85vw;
            object-fit: contain;
        }
    }
}
