.homeThreeItem {
    :global {
        ::-webkit-scrollbar {
            width: 5px;
        }

        ::-webkit-scrollbar-thumb {
            background-color: var(--scrollbar-thumb-color);
            border-radius: 5px;
        }

        ::-webkit-scrollbar-track {
            background-color: #384b5d;
        }
    }
}
